import {
  DEFAULT_MOBILE_MEDIA_QUERY,
  RSVP_LOGIN_FLOW,
} from "@App/constants/appConstants";
import { Bars, Bell, Calendar, MapMarker, User } from "@Components/icons";
import {
  ActionBanner,
  Button,
  Image,
  Loader,
  Menu,
  Modal,
  NavbarItems,
  NotificationItem,
  RegistrationDetails,
  ResendEmail,
  ResultBanner,
  UserMenu,
} from "@Components/index";
import { Footer } from "@Layouts/index";
import { Login, Registration } from "@Pages/index";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import NavbarLogic from "./NavbarLogic";
import { NavbarProps } from "./NavbarPropTypes";

import themedStyles from "@Assets/styles/themedStyles.scss";

const ICONS_SIZE = 22;

const Navbar = (componentProps: NavbarProps) => {
  const props = NavbarLogic();

  return (
    <>
      {!props.isMobileEmbed && !props.areCommunityUserAccountsDisabled && (
        <>
          {/* TODO: Temporary forced banner for hurricane updates */}
          <ActionBanner backgroundColor="#fac42f" fontColor="#000000">
            <>
              <span>
                See{" "}
                <Link
                  to={"/cms/town-hall"}
                  className="action-banner-text-underline"
                  style={{ color: "#000000" }}
                >
                  Town Hall
                </Link>{" "}
                &{" "}
                <Link
                  to={"/cms/town-hall-north"}
                  className="action-banner-text-underline"
                  style={{ color: "#000000" }}
                >
                  Town Hall North
                </Link>{" "}
                pages for updates on current openings/closures due to Hurricane
                Milton
              </span>
            </>
          </ActionBanner>
          {/* {!props.isLogged && (
            <ActionBanner
              text={`${props.nonLoggedInUserWelcome} Create an account today for event registrations, reservations & more!`}
              backgroundColor={"#85BF6C"}
              fontColor={"#1D345E"}
              customIcon={
                <img
                  className={"cms-main__icon"}
                  alt={""}
                  src={
                    props.tenantConfig?.cmsSettings.websiteSettings
                      .communityIcon
                  }
                />
              }
              onClick={() => props.setShowRegistration(true)}
            />
          )}
          {!props.navigationMenuVisible && !props.isEmailVerified && (
            <ActionBanner
              text={"Please check your email to verify your account."}
              noUnderline
              actionLinkText={"Click here to resend"}
              actionClick={() => {
                props.setShowResendEmailModal(true);
              }}
            />
          )}
          {!props.navigationMenuVisible &&
            props.isEmailVerified &&
            !props.isResidentialStatusProvided && (
              <ActionBanner
                text={
                  "Account Validation Required: Please click here to ensure your account setup is complete!"
                }
                onClick={() => {
                  window.history.replaceState(
                    null,
                    "",
                    window.location.pathname,
                  );
                  props.showAccountDetails();
                }}
                backgroundColor={themedStyles.actionBannerAlternativeBackground}
                fontColor={themedStyles.actionBannerAlternativeText}
              />
            )} */}
        </>
      )}

      <div className={"nav-container"}>
        <div
          className={`nav-wrapper ${
            props.isMobileEmbed ? "nav-wrapper-hidden" : ""
          }`}
          onClick={() => {
            // setIsUserMenuVisible(false);
          }}
        >
          <div className="nav">
            {/*Logo link to root*/}
            <div className={"nav-logo-container"}>
              <Link
                to={"/"}
                className={"nav-logo-link"}
                onClick={() => {
                  props.setIsUserMenuVisible(false);
                }}
              >
                <Image
                  className="nav-logo-link-icon"
                  src={
                    props.tenantConfig?.cmsSettings?.websiteSettings
                      ?.communityIcon
                  }
                  alt="Logo Icon"
                  width="40px"
                  height="40px"
                />
                <Image
                  className="nav-logo-link-banner"
                  src={
                    props.tenantConfig?.cmsSettings?.websiteSettings
                      ?.communityLogo
                  }
                  alt="Logo Banner"
                  width="173px"
                  height="40px"
                />
              </Link>
            </div>

            {/* Desktop view */}
            <div className={"nav-menu-items-center"}>
              <NavbarItems />
            </div>

            {/* Mobile view and right buttons. TODO: Should work like the view above */}
            <div
              className={`nav-menu-items-right ${
                props.navigationMenuVisible ? "visible" : ""
              }`}
            >
              {props.isLogged && (
                <div className={"user-welcome"}>
                  {`Hello ${props.userInfo?.firstName ?? ""}`}
                </div>
              )}
              <div className={"mobile-items"}>
                <NavbarItems />
              </div>

              <div className={"main-items"}>
                {/*  Location icon */}
                <Link className={"nav-button-icon-link"} to={"/locations"}>
                  <MapMarker
                    size={ICONS_SIZE}
                    color={
                      props.currentRoute === "/locations"
                        ? themedStyles.navigationIconActiveColor
                        : themedStyles.navigationIconColor
                    }
                  />
                  <span className={"nav-button-icon-link-text"}>Map</span>
                </Link>

                {/*  Calendar icon */}
                <Link
                  className={"nav-button-icon-link"}
                  to={"/calendar"}
                  onClick={() => props.setIsNotificationsMenuVisible(false)}
                >
                  <Calendar
                    color={
                      props.currentRoute === "/calendar"
                        ? themedStyles.navigationIconActiveColor
                        : themedStyles.navigationIconColor
                    }
                    size={ICONS_SIZE}
                  />
                  <span className={"nav-button-icon-link-text"}>Events</span>
                </Link>

                {props.isLogged && (
                  <div className={"nav-user-actions"}>
                    {/*  Notifications IconLink & Modal*/}
                    <div
                      onMouseOver={() =>
                        props.setIsNotificationsMenuVisible(true)
                      }
                      onMouseLeave={() =>
                        setTimeout(
                          () => props.setIsNotificationsMenuVisible(false),
                          400,
                        )
                      }
                    >
                      <Link
                        className={"nav-button-icon-link"}
                        to={"/notifications"}
                      >
                        <Bell
                          color={
                            props.currentRoute === "/notifications"
                              ? themedStyles.navigationIconActiveColor
                              : themedStyles.navigationIconColor
                          }
                          size={ICONS_SIZE}
                        />
                        <span className={"nav-button-icon-link-text"}>
                          Notifications
                        </span>
                      </Link>
                      {props.isNotificationsMenuVisible &&
                        !props.isUserMenuVisible && (
                          <div className={"nav-user-notifications-modal"}>
                            <Menu
                              backgroundColor="#FFFFFF"
                              fixedPosition={"right"}
                            >
                              <Link
                                to={"/notifications"}
                                style={{ textDecoration: "none" }}
                              >
                                <div className="nav-notifications-container">
                                  {props.isLoadingNotifications ? (
                                    <Loader color={"#000"} />
                                  ) : (
                                    props.notificationsData?.pages[0].items
                                      .slice(0, 5)
                                      .map((notification) => {
                                        return (
                                          <NotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            maxWidth="375px"
                                            isMenuItem
                                          />
                                        );
                                      })
                                  )}
                                  <p className="nav-notifications-container__bottom">
                                    View All
                                  </p>
                                </div>
                              </Link>
                            </Menu>
                          </div>
                        )}
                    </div>

                    {/*  User Profile IconLink & Modal*/}
                    <div>
                      <Link
                        className={"nav-button-icon-link"}
                        to="/profile"
                        onClick={(e) => {
                          if (
                            !window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY)
                              .matches
                          ) {
                            e.preventDefault();
                          }
                          props.setIsNotificationsMenuVisible(false);
                          if (props.isLogged) {
                            props.setIsNotificationsMenuVisible(false);
                            props.setIsUserMenuVisible(
                              !props.isUserMenuVisible,
                            );
                          } else {
                            localStorage.removeItem(RSVP_LOGIN_FLOW);
                            props.setShowLogin(true);
                          }
                        }}
                      >
                        <User
                          color={
                            props.currentRoute === "/profile"
                              ? themedStyles.navigationIconActiveColor
                              : themedStyles.navigationIconColor
                          }
                          size={ICONS_SIZE}
                        />
                        <span className={"nav-button-icon-link-text"}>
                          Profile & Settings
                        </span>
                      </Link>
                      {props.isUserMenuVisible && (
                        <div className={"nav-user-menu-modal"}>
                          <Menu
                            backgroundColor={"#fff"}
                            fixedPosition={"right"}
                          >
                            <UserMenu
                              firstname={props.userInfo?.firstName ?? ""}
                              loyaltyPoints={1000}
                              onLogout={props.handleLogout}
                              dataCy="logout-button"
                            />
                          </Menu>
                        </div>
                      )}
                    </div>
                    <Button
                      className={"mobile-logout"}
                      text={"Log Out"}
                      isSecondary
                      onClick={props.handleLogout}
                    />
                  </div>
                )}

                {/*  Auth section, we should wrap all this section with the new tenant prop */}
                {!props.isLogged && !props.areCommunityUserAccountsDisabled && (
                  <div className={"nav-user-actions"}>
                    <Button
                      text={"LOG IN"}
                      className={"nav-user-access-button"}
                      onClick={() => {
                        props.setNavigationMenuVisible(false);
                        if (props.isLogged) {
                          props.setIsNotificationsMenuVisible(false);
                          props.setIsUserMenuVisible(!props.isUserMenuVisible);
                        } else {
                          localStorage.removeItem(RSVP_LOGIN_FLOW);
                          props.setShowLogin(true);
                        }
                      }}
                      data-cy="log-in-button"
                      isSecondary={
                        !window.matchMedia(DEFAULT_MOBILE_MEDIA_QUERY).matches
                      }
                    />

                    <Button
                      text={"SIGN UP"}
                      className={"nav-user-access-button"}
                      isSecondary
                      onClick={() => {
                        props.setShowRegistration(true);
                      }}
                      data-cy="sign-up-button"
                    />
                  </div>
                )}
                {props.thirdPartyNavbar && (
                  <div className={"nav-user-actions"}>
                    <Button
                      text={
                        props.thirdPartyNavbar?.displayText?.toUpperCase() ??
                        props.thirdPartyNavbar?.displayText
                      }
                      className={"nav-user-access-button"}
                      onClick={() => {
                        window.open(props.thirdPartyNavbar?.url, "_blank");
                      }}
                      isSecondary
                    />
                  </div>
                )}
              </div>
            </div>
            <Button
              onClick={() => {
                props.setNavigationMenuVisible(!props.navigationMenuVisible);
              }}
              className={"nav-mobile-menu-button"}
              icon={<Bars color={themedStyles.navigationIconColor} />}
            />
          </div>
        </div>
        {!props.navigationMenuVisible && (
          <div
            className={"nav-content-container"}
            onClick={() => {
              props.setIsUserMenuVisible(false);
            }}
          >
            {/* Content */}
            <div className={`nav-content`}>
              {props.showResultBanner && props.isLogged && (
                <ResultBanner
                  text={props.resultBannerMessage}
                  onClose={() => {
                    props.hideResultBanner();
                  }}
                />
              )}
              {componentProps.children}
            </div>
          </div>
        )}
      </div>
      {!props.isMobileEmbed && !props.navigationMenuVisible && <Footer />}
      {(props.isProcessing ||
        props.isloadingUserData ||
        !props.tenantConfig) && <Loader text={props.processingMessage} modal />}
      <ResendEmail
        isVisible={props.showResendEmailModal}
        onClose={() => {
          props.setShowResendEmailModal(false);
        }}
      />
      <Modal
        isVisible={props.showLogin && !props.areCommunityUserAccountsDisabled}
        onClose={() => {
          props.setShowLogin(false);
        }}
      >
        <Login
          errorMessage={props.loginErrorMessage}
          onClose={(showRegistration) => {
            props.setShowLogin(false);
            if (showRegistration) {
              props.setShowRegistration(true);
            }
          }}
        />
      </Modal>
      <Modal
        isVisible={
          props.showRegistration && !props.areCommunityUserAccountsDisabled
        }
        onClose={() => {
          props.handleRegistrationModalClose();
        }}
      >
        <Registration
          onClose={(showLogin) => {
            props.handleRegistrationModalClose(showLogin);
          }}
          duplicateAccountError={props.isDuplicateAccountError}
          userData={props.userDataParameters}
        />
      </Modal>
      <Modal
        isVisible={
          props.showRegistrationUserDetails &&
          !props.areCommunityUserAccountsDisabled
        }
      >
        <RegistrationDetails
          tenantName={props.tenantConfig?.name}
          onSave={props.onRegistrationDetailsSave}
          onClose={props.onRegistrationDetailsClose}
        />
      </Modal>
    </>
  );
};

export default Navbar;
