import { Information } from "@Components/icons";
import "./ActionBanner.scss";
import { ActionBannerBannerProps } from "./ActionBannerPropTypes";

const ActionBanner = ({
  text,
  noUnderline,
  actionLinkText,
  backgroundColor,
  fontColor,
  customIcon,
  children,
  onClick,
  actionClick,
}: ActionBannerBannerProps) => {
  return (
    <div
      className={"action-banner"}
      style={{
        ...(backgroundColor && {
          backgroundColor: backgroundColor,
        }),
      }}
      data-cy="action-banner"
    >
      <div className={"action-banner-info"}>
        {customIcon ? (
          customIcon
        ) : (
          <Information
            {...(fontColor && {
              color: fontColor,
            })}
          />
        )}
        <label
          className={`action-banner-text ${
            !noUnderline && "action-banner-text-underline"
          } ${onClick && "action-banner-text-link"}`}
          onClick={() => {
            onClick && onClick();
          }}
          style={{
            ...(fontColor && {
              color: fontColor,
            }),
          }}
          data-cy="action-banner-text"
        >
          {text}
        </label>
      </div>
      {actionLinkText && (
        <label
          className={`action-banner-text action-banner-text-underline action-banner-text-link`}
          onClick={() => {
            actionClick && actionClick();
          }}
          style={{
            ...(fontColor && {
              color: fontColor,
            }),
          }}
          data-cy="action-banner-link"
        >
          {actionLinkText}
        </label>
      )}
      {!!children && (
        <label
          className={`action-banner-text`}
          style={{
            ...(fontColor && {
              color: fontColor,
            }),
          }}
        >
          {children}
        </label>
      )}
    </div>
  );
};

export default ActionBanner;
